import moment from 'moment'
import Chart from 'chart.js'
import { ServiceFactory } from '@/services/ServiceFactory'
const _barMetricsService = ServiceFactory.get('BarMetricsService')
// const _citiesService = ServiceFactory.get('CitiesService')

export default {
  data () {
    return {
      url: '',
      headers: [
        {
          text: 'Service From',
          align: 'start',
          sortable: false,
          value: 'serviceFrom'
        },
        { text: 'Total Android', align: 'center', sortable: false, value: 'android' },
        { text: 'Total iOS', align: 'center', sortable: false, value: 'ios' },
        { text: 'Total otros', align: 'center', sortable: false, value: 'others' },
        { text: 'Click totales', align: 'center', sortable: false, value: 'total' },
        { text: '', value: 'data-table-expand' }
        // { text: 'Opciones', value: 'options' }
      ],
      items: [],
      loading: false,
      loading1: false,
      loading2: false,
      loading3: false,
      menu2: false,
      menu: false,
      months: [],
      dates: [],
      chartData: {},
      chart: [],
      pieChart: {},
      expanded: [],
      singleExpand: true,
      chartService: null,
      chServData: {},
      pieChartServ: null,
      pieChServData: {},
      loadingChart: false,
      loadingPicker: false,
      loadingMonth: false,
      total: null,
      historic: null,
      chartAmp: false,
      pAndroid: 0.0,
      pIos: 0.0,
      pOther: 0.0,
      topBusiness: [],
      topUsers: [],
      topPromoCodes: [],
      categoryAverage: [],
      topItems: 2,
      topCodes: 2,
      topCategories: 2,
      topNumbUsers: 2,
      topZones: [],
      topZonesItems: 2,
      topDiscounts: [],
      topDiscountsItems: 2,
      topHours: [],
      topHoursItems: 2,
      topDays: [],
      topDaysItems: 2,
      bookingsMet: {
        bookings: {},
        average: {},
        business: {},
        people: {},
        firstBooking: {
          first: null,
          firstUnauth: null
        },
        buyback: {
          total: null,
          rate: null,
          unauth: null
        },
        gainAverage: null
      },
      growth: {},
      monthsArray: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      cities: [],
      cityId: null,
      cityIdGrowth: null,
      fdg: '',
      ldg: '',
      barId: null
    }
  },
  created () {
    this.initialize()
  },
  watch: {
    '$route' (to, from) {
      // if (from.params.id !== to.params.id) {
      return this.initialize()
      // }
    }
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        this.barId = this.$route.params.id
        var date1 = moment().add(5, 'days').format('YYYY-MM-DD') // moment().format('YYYY-MM-DD')
        var date2 = moment().subtract(5, 'days').format('YYYY-MM-DD')
        this.dates = [date2, date1]
        console.log(date1, date2)

        // this.currentItem = null
        this.loading = true
        this.loading1 = true
        this.loading2 = true
        this.loading3 = true

        // chart settings
        try {
          var metricsResp = await _barMetricsService.range(this.dates[1], this.dates[0], this.barId)
          console.log(metricsResp)
          // if (urlStatsResp.total > 0) {
          //   this.pAndroid = Math.round((urlStatsResp.os.data[0] / urlStatsResp.total) * 10000) / 100
          //   this.pIos = Math.round((urlStatsResp.os.data[1] / urlStatsResp.total) * 10000) / 100
          //   this.pOther = Math.round((urlStatsResp.os.data[2] / urlStatsResp.total) * 10000) / 100
          // } else {
          //   this.pAndroid = 0
          //   this.pIos = 0
          //   this.pOther = 0
          // }
          // this.total = urlStatsResp.total
          // this.historic = urlStatsResp.historic
          this.chartData = {
            labels: metricsResp.metrics.labels, // urlStatsResp.dataset.labels,
            datasets: [
              {
                label: 'Completados',
                data: metricsResp.metrics.dataCompleted, // urlStatsResp.dataset.data,
                backgroundColor: '#43A047'
                // tension: 0.1
                // backgroundColor: chartDatasetsBackground
              },
              {
                label: 'Cancelados',
                data: metricsResp.metrics.dataCancelled, // urlStatsResp.dataset.data,
                backgroundColor: '#C62828'
                // tension: 0.1
                // backgroundColor: chartDatasetsBackground
              },
              {
                label: 'Activos',
                data: metricsResp.metrics.dataActive, // urlStatsResp.dataset.data,
                backgroundColor: '#1976D2'
                // tension: 0.1
                // backgroundColor: chartDatasetsBackground
              },
              {
                label: 'No asistidos',
                data: metricsResp.metrics.dataNoShow, // urlStatsResp.dataset.data,
                backgroundColor: '#000'
                // tension: 0.1
                // backgroundColor: chartDatasetsBackground
              }
            ]
          }
          var canvas = document.getElementById('chartLine').getContext('2d')
          this.chart[0] = new Chart(canvas, {
            type: 'bar',
            data: this.chartData,
            options: {
              // interaction: {
              //   intersect: false,
              //   mode: 'index'
              // },
              title: {
                display: true,
                text: 'Metricas'
              },
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true
                }
              },
              legend: { display: true }
            }
          })

          // Top Business request
          const topResp = await _barMetricsService.top(this.dates[1], this.dates[0], this.barId)
          console.log(topResp)
          // this.topBusiness = topResp.topBusiness
          // this.topPromoCodes = topResp.topPromoCodes
          // this.categoryAverage = topResp.categoryAverage
          this.topUsers = topResp.topUsers
          // this.topZones = topResp.topZones
          this.topDiscounts = topResp.topDiscounts
          this.topHours = topResp.topHours
          this.topDays = topResp.topDays
          this.loading1 = false

          // Bookings metrics
          const metResp = await _barMetricsService.bookingsMetrics(this.dates[1], this.dates[0], this.barId)
          console.log(metResp)
          this.bookingsMet = metResp
          this.loading2 = false

          // Growth metics
          // this.months = [moment().subtract(1, 'months').format('YYYY-MM'), moment().format('YYYY-MM')]
          // this.growth = await _metricsService.growth(this.months[0], this.months[1], this.cityIdGrowth)
          // console.log(this.growth)

          // this.pieChart = {
          //   labels: urlStatsResp.os.labels,
          //   datasets: [
          //     {
          //       label: 'Pie',
          //       data: urlStatsResp.os.data,
          //       backgroundColor: ['Green', 'Yellow', 'Orange']
          //     }
          //   ]
          // }
          // var canvas2 = document.getElementById('chartPie').getContext('2d')
          // this.chart[1] = new Chart(canvas2, {
          //   type: 'pie',
          //   data: this.pieChart,
          //   options: {
          //     title: {
          //       display: true,
          //       text: 'Clicks por S.O.'
          //     },
          //     responsive: true,
          //     // scales: {
          //     //   y: {
          //     //     beginAtZero: true
          //     //   }
          //     // },
          //     legend: { display: false }
          //   }
          // })
        } catch (error) {
          console.log(error)
        }
      } else {
        this.$router.push({ name: 'companies' })
      }
    },
    more () {
      console.log(this.topItems)
      if (this.topItems === 2) {
        this.topItems = 100
      } else {
        this.topItems = 2
      }
    },
    moreCodes () {
      console.log(this.topCodes)
      if (this.topCodes === 2) {
        this.topCodes = 100
      } else {
        this.topCodes = 2
      }
    },
    moreCategories () {
      console.log(this.topCategories)
      if (this.topCategories === 2) {
        this.topCategories = 100
      } else {
        this.topCategories = 2
      }
    },
    moreUsers () {
      console.log(this.topNumbUsers)
      if (this.topNumbUsers === 2) {
        this.topNumbUsers = 100
        console.log(this.topNumbUsers, 100)
      } else {
        this.topNumbUsers = 2
        console.log(this.topNumbUsers, 2)
      }
      console.log(this.topNumbUsers)
    },
    moreZones () {
      if (this.topZonesItems === 2) {
        this.topZonesItems = 100
      } else {
        this.topZonesItems = 2
      }
    },
    moreDiscounts () {
      if (this.topDiscountsItems === 2) {
        this.topDiscountsItems = 100
      } else {
        this.topDiscountsItems = 2
      }
    },
    moreHours () {
      if (this.topHoursItems === 2) {
        this.topHoursItems = 100
      } else {
        this.topHoursItems = 2
      }
    },
    moreDays () {
      if (this.topDaysItems === 2) {
        this.topDaysItems = 100
      } else {
        this.topDaysItems = 2
      }
    },
    async changeData () {
      console.log(this.dates)
      try {
        this.loadingPicker = true
        // var urlStatsResp = await _urlCounterService.urlStats(this.dates[1], this.dates[0], this.url)
        var metricsResp = await _barMetricsService.range(this.dates[1], this.dates[0], this.barId)
        // this.total = urlStatsResp.total
        // this.historic = urlStatsResp.historic
        console.log(metricsResp)
        this.chart[0].config.data.labels = metricsResp.metrics.labels
        this.chart[0].config.data.datasets[0].data = metricsResp.metrics.dataCompleted
        this.chart[0].config.data.datasets[1].data = metricsResp.metrics.dataCancelled
        this.chart[0].config.data.datasets[2].data = metricsResp.metrics.dataActive
        this.chart[0].config.data.datasets[3].data = metricsResp.metrics.dataNoShow
        this.chart[0].update()

        // Top Business request
        const topResp = await _barMetricsService.top(this.dates[1], this.dates[0], this.barId)
        console.log(topResp)
        // this.topBusiness = topResp.topBusiness
        // this.topPromoCodes = topResp.topPromoCodes
        // this.categoryAverage = topResp.categoryAverage
        this.topUsers = topResp.topUsers
        // this.topZones = topResp.topZones
        this.topDiscounts = topResp.topDiscounts
        this.topHours = topResp.topHours
        this.topDays = topResp.topDays

        // Bookings metrics
        const metResp = await _barMetricsService.bookingsMetrics(this.dates[1], this.dates[0], this.barId)
        console.log(metResp)
        this.bookingsMet = metResp
        // this.chart[1].config.data.labels = urlStatsResp.os.labels
        // this.chart[1].config.data.datasets[0].data = urlStatsResp.os.data
        // this.chart[1].update()
        // if (urlStatsResp.total > 0) {
        //   this.pAndroid = Math.round((urlStatsResp.os.data[0] / urlStatsResp.total) * 10000) / 100
        //   this.pIos = Math.round((urlStatsResp.os.data[1] / urlStatsResp.total) * 10000) / 100
        //   this.pOther = Math.round((urlStatsResp.os.data[2] / urlStatsResp.total) * 10000) / 100
        // } else {
        //   this.pAndroid = 0
        //   this.pIos = 0
        //   this.pOther = 0
        // }
        // if (this.chartService && this.pieChartServ) {
        //   var servStatsResp = await _urlCounterService.servStats(this.dates[1], this.dates[0], this.url, this.expanded[0].serviceFrom)
        //   // console.log(servStatsResp)
        //   this.chartService.config.data.labels = servStatsResp.dataset.labels
        //   this.chartService.config.data.datasets[0].data = servStatsResp.dataset.data
        //   this.chartService.update()
        //   this.pieChartServ.config.data.labels = servStatsResp.os.labels
        //   this.pieChartServ.config.data.datasets[0].data = servStatsResp.os.data
        //   this.pieChartServ.update()
        // }
        this.loadingPicker = false
      } catch (error) {
        this.loadingPicker = false
        console.log(error)
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
    // async getGrowing (m) {
    //   try {
    //     this.loadingMonth = true
    //     this.$refs.menu.save(m)
    //     this.months.sort()
    //     console.log(this.months)
    //     var firstDate = this.months[0] + '-01'
    //     var lm = this.months[this.months.length - 1] + '-01'
    //     var lastDate = moment(lm).endOf('month').format('YYYY-MM-DD')
    //     console.log(firstDate, lastDate)
    //     this.growth = await _metricsService.growth(firstDate, lastDate, this.cityIdGrowth)
    //     this.loadingMonth = false
    //   } catch (error) {
    //     this.loadingMonth = false
    //     console.log(error)
    //   }
    // },
    // async getGrowingCity () {
    //   try {
    //     this.loadingMonth = true
    //     // this.$refs.menu.save(m)
    //     this.months.sort()
    //     console.log(this.months)
    //     var firstDate = this.months[0] + '-01'
    //     var lm = this.months[this.months.length - 1] + '-01'
    //     var lastDate = moment(lm).endOf('month').format('YYYY-MM-DD')
    //     console.log(firstDate, lastDate)
    //     this.growth = await _metricsService.growth(firstDate, lastDate, this.cityIdGrowth)
    //     this.loadingMonth = false
    //   } catch (error) {
    //     this.loadingMonth = false
    //     console.log(error)
    //   }
    // }
    // amplify () {
    //   var chCol = document.getElementById('chartCol')
    //   this.chartAmp = !this.chartAmp
    //   console.log(chCol, this.chartAmp)
    //   if (this.chartAmp) {
    //     chCol.className = 'col-12 text-center'
    //   } else {
    //     chCol.className = 'col-9 text-center'
    //   }
    // },
    // goBack () {
    //   this.$router.push({ name: 'url-counter' }).catch(() => {})
    // }
  }
}
